import { EventEmitter } from "events";
import { compiledClientConfig } from "config";
import { apiReq } from "./api/apiReq";

const DEFAULT_VERSION_NAME = "unknown";

interface IDeploymentInfo {
	GITHUB_REF: string;
	RUN_NUMBER: string;
	COMMIT_HASH: string;
}

const isDeploymentInfo = (val: unknown): val is IDeploymentInfo => {
	const asDeploymentInfo = val as IDeploymentInfo;
	return (
		typeof asDeploymentInfo === "object" &&
		typeof asDeploymentInfo.GITHUB_REF === "string" &&
		typeof asDeploymentInfo.RUN_NUMBER === "string" &&
		typeof asDeploymentInfo.COMMIT_HASH === "string"
	);
};

const getDeploymentInfo = async (): Promise<IDeploymentInfo | null> => {
	try {
		const { data } = await apiReq("GET", "/deploymentInfo", null, {
			pathPrefix: compiledClientConfig.basePrefix
		});
		return isDeploymentInfo(data) ? data : null;
	} catch (_error) {
		return null;
	}
};

export const getCurrentVersion = async (): Promise<string> => {
	const deploymentInfo = await getDeploymentInfo();
	if (deploymentInfo && isDeploymentInfo(deploymentInfo)) {
		return `frontend-#${deploymentInfo.RUN_NUMBER}-${deploymentInfo.COMMIT_HASH}`;
	}
	return DEFAULT_VERSION_NAME;
};

export const reloadOnNewVersion = () => {
	// we don't want to create infinite loop of reloads
	if (!getWithExpiry<boolean>("reload_on_new_version")) {
		setWithExpiry("reload_on_new_version", true, 10000);
		window.location.reload();
		return true;
	}
	return false;
};

function setWithExpiry(key: string, value: unknown, ttl: number) {
	const item = {
		value,
		expiry: new Date().getTime() + ttl
	};
	localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry<T>(key: string) {
	const itemString = window.localStorage.getItem(key);
	if (!itemString) return null;

	const item = JSON.parse(itemString);
	const isExpired = new Date().getTime() > item.expiry;

	if (isExpired) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value as T;
}

export const CHECK_EVENT = "check";
export const checkForNewVersionEventEmitter = new EventEmitter();
