import classNames from "classnames";
import React, { useMemo } from "react";
import { useStyles } from "./styles";
import { TooltipOnOverflow } from "../TooltipOnOverflow";
import { Typography, type TTypographyVariant } from "../Typography";

type TProps = {
	size?: "small" | "medium" | "large";
	title?: React.ReactNode;
	body?: React.ReactNode;
	bodyTooltipOnOverflow?: boolean;
};

export const TitleBody: FC<TProps> = ({ body, className, innerRef, size = "medium", title, bodyTooltipOnOverflow }) => {
	const classes = useStyles({ size });

	const [titleVariant, bodyVariant]: [TTypographyVariant, TTypographyVariant] = useMemo(() => {
		switch (size) {
			case "small":
				return ["text_sm_sb", "text_sm_reg"];
			case "medium":
				return ["body_sb", "body_reg"];
			case "large":
				return ["title_sb", "body_reg"];
		}
	}, [size]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			{title ? <Typography variant={titleVariant}>{title}</Typography> : null}
			{body ? (
				bodyTooltipOnOverflow ? (
					<TooltipOnOverflow textVariant={bodyVariant} className={classes.body} content={body} />
				) : (
					<Typography variant={bodyVariant} className={classes.body}>
						{body}
					</Typography>
				)
			) : null}
		</div>
	);
};
