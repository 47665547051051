import React from "react";
import { ErrorModal } from "components/ui/ErrorModal";
import { useIsOpenState } from "hooks/useIsOpenState";
import { EntitleError } from "utils/errors/entitleError";
import NewVersionError from "utils/errors/newVersionError";
import { logrocketLogError } from "utils/logrocket";
import { reloadOnNewVersion } from "utils/version";
import type { FallbackProps } from "react-error-boundary";

export const DefaultErrorBoundary: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
	const { open, isOpen, close } = useIsOpenState();

	const closeErrorBoundary = React.useCallback(() => {
		resetErrorBoundary();
		close();
	}, [close, resetErrorBoundary]);

	const normalizedError = React.useMemo<EntitleError | Error | null>(() => {
		if (
			error &&
			(error.message.includes("Failed to fetch dynamically imported module:") ||
				error.message.includes("Importing a module script failed"))
		) {
			return new NewVersionError();
		}
		return error as Error;
	}, [error]);

	React.useEffect(() => {
		if (normalizedError instanceof NewVersionError) {
			const didReload = reloadOnNewVersion();
			if (didReload) return;
		}
		if (normalizedError && !isOpen) open();
	}, [normalizedError, isOpen, open]);

	React.useEffect(() => {
		if (!normalizedError) return;
		logrocketLogError(normalizedError, { location: "ErrorBoundary" });
	}, [normalizedError]);

	if (error instanceof EntitleError && error.suppressError) {
		return null;
	}
	return <ErrorModal isOpen={isOpen} error={normalizedError} onClose={closeErrorBoundary} useUnwrapped />;
};
